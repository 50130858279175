// Aqui se centralizan los strings de las rutas al API
export class API {
    public static webApiBase = process.env.VUE_APP_ROOT_API;
    public static webApiBaseImages = process.env.VUE_APP_ROOT_PATH+'Images/';
    public static webApiBaseinventario = process.env.VUE_APP_ROOT_PATH+'inventario/';
    public static webApiBasedatos_empresa = process.env.VUE_APP_ROOT_PATH+'datos_empresa/';
    public static webApiBaseRootPath = process.env.VUE_APP_ROOT_PATH;//'/'

    
    public static login = API.webApiBase + 'Login';
    public static usuarios = API.webApiBase + 'Usuarios';
    public static roles = API.webApiBase + 'Roles';
    public static VersionBack = API.webApiBase + 'VersionBack';
    /*************************** */
    public static SendingEmails = API.webApiBase + 'SendingEmails';
    public static servidor_smtp = API.webApiBase + 'servidor_smtp';
    public static usuario = API.webApiBase + 'usuarios';
    public static pantalla_lista = API.webApiBase + 'pantalla_lista';
    public static enumeracion = API.webApiBase + 'enumeraciones';
    public static Connect = API.webApiBase + 'Connect';
    public static cambioscontrasenasperdidas = API.webApiBase + 'cambioscontrasenasperdidas';
    public static rol = API.webApiBase + 'roles';
    public static mantenimiento_permiso = API.webApiBase + 'mantenimiento_permisos';
    public static permiso = API.webApiBase + 'permiso';
    public static TestAvatar = API.webApiBase + 'TestAvatar';
    public static menu_option = API.webApiBase + 'menu_options';
    public static centro_trabajo = API.webApiBase + 'centro_trabajo';
    public static convenio = API.webApiBase + 'convenios';
    public static datos_empresa = API.webApiBase + 'datos_empresa';
    public static tipos_contrato = API.webApiBase + 'tipos_contrato';
    public static hist_contratos_empleado = API.webApiBase + 'hist_contratos_empleados';
    public static fichajes_empleado = API.webApiBase + 'fichajes_empleado';
    public static tipos_fichaje = API.webApiBase + 'tipos_fichaje';
    public static public = API.webApiBase + 'public';
    public static cliente = API.webApiBase + 'clientes';
    public static Log = API.webApiBase + 'Log';
    public static geo_loc_fichaje = API.webApiBase + 'geo_loc_fichajes';
    public static fichajes_empleado_historial = API.webApiBase + 'fichajes_empleado_historial';
    public static variables = API.webApiBase + 'Variables';
    public static calendario_laboral = API.webApiBase + 'calendario_laboral';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';



}
